import Icons from "icons/sidebar";
import {
  List,
  Add,
  Shield,
  Dashboard
} from "@mui/icons-material";

const index = [
  {
    title: "Dashboard",
    Icon: Dashboard,
    path: "/dashboard",
  },
  {
    title: "Business Profile",
    Icon: Shield,
    path: "/dashboard/business-profile",
  },
  {
    title: "Assets",
    Icon: List,
    path: "/dashboard/assets/list",
  },
  {
    title: "Add asset",
    Icon: Add,
    path: "/dashboard/asset/add-new",
  },
  {
    title: "Bookings",
    Icon: List,
    path: "/dashboard/user-list",
  },
];

export default index;
